import axios from 'axios';
import cookie from 'react-cookies';
import StorageGateway from 'lib/storage-gateway';
import { toast } from 'react-toastify';

const axiosInstance = axios.create();

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (configuration) => {
    const config = configuration;
    const authToken = StorageGateway.get('authToken');
    const token = StorageGateway.get('Token');
    const oAuthToken = StorageGateway.get('oAuthToken');
    const companyId = StorageGateway.get('company_id');
    const uniqueUuid = StorageGateway.get('uniqueUuid');
    const pluginToken = StorageGateway.get('pluginToken');
    if (config.url.indexOf("hereapi") === -1){
      config.headers["Access-Control-Allow-Origin"] = "*";
      config.headers["Cache-Control"] = "no-cache";
      config.headers["X-XSS-Protection"] = "1; mode=block";
      config.headers["Strict-Transport-Security"] = "max-age=31536000";
      config.headers["X-Content-Type-Options"] = "nosniff";
      if (companyId && config.url.indexOf("reject_session")===-1 && config.url.indexOf('lambda-url')===-1)config.headers.company = companyId
       
      // if (authToken) config.headers.Authorization =`Token ${authToken}`;
      if (uniqueUuid) config.headers.uniqueUuid = uniqueUuid;
    }
    if((config.url.indexOf("konect")>-1) && (config.url.indexOf("pdf")>-1 || config.url.indexOf("reject_session")>-1 || config.url.indexOf("job-costing")>-1 ) || config.url.indexOf('lambda-url')>-1) config.headers.Authorization = `Bearer ${token}`;
    else if ((config.url.indexOf('pdf2')>-1  ||  config.url.indexOf('async-export')>-1)) config.headers.Authorization = `Token ${token}`
    else if (config.url.indexOf("hereapi") === -1 && oAuthToken && config.url.indexOf("konect")===-1)  config.headers.Authorization = `Bearer ${oAuthToken}`;
    else if (config.url.indexOf("hereapi") === -1 && pluginToken && config.url.indexOf("konect") !== -1) 
      {
        config.headers.Authorization = `${pluginToken}`;
      }
    return config;
  },
  error => Promise.reject(error),
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  response => response.data,
  (error) => {
    if (error.response &&
      (error.response.status === 401 ||
        error.response.data.detail === 'Invalid token.')
    ) {
      let domain = window.location.hostname;
      const host = window.location.hostname.split('.');
      if (host[0].indexOf('admin') >= 0) domain = `.${host[1]}.${host[2]}`;

      // cookie.remove('authToken', { path: '/' });
      StorageGateway.remove('oAuthToken', {path: '/'})
      // cookie.remove('Authorization', { path: '/', domain });
      StorageGateway.remove('company_id', { path: '/' });
      StorageGateway.remove('uniqueUuid', { path: '/' });
      StorageGateway.remove('graniteToken');
      StorageGateway.remove('userId');
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      // toast.error('Your session has expired.');
    }
    else if(error.response && error.response.status === 400) {
      if(error.response.data.auth_error) {
        let domain = window.location.hostname;
        const host = window.location.hostname.split('.');
        if (host[0].indexOf('admin') >= 0) domain = `.${host[1]}.${host[2]}`;
        toast.error(error.response.data.auth_error[0]);
        // cookie.remove('authToken', { path: '/' });
        StorageGateway.remove('oAuthToken', {path: '/'})
        // cookie.remove('Authorization', { path: '/', domain });
        StorageGateway.remove('company_id', { path: '/' });
        StorageGateway.remove('uniqueUuid', { path: '/' });
        StorageGateway.remove('graniteToken');
        StorageGateway.remove('userId');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
      else if(error.response.data.method_error) {
        toast.error(error.response.data.method_error[0]);
      }
    }
    return Promise.reject(error?.response?.data ||error);
  },
);

export default axiosInstance;
