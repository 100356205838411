import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import cookie from "react-cookies";

import * as UserDucks from "ducks/accounts/user";
import * as PaymentDucks from "ducks/vendors/payment";
import * as BasicSettingDucks from "ducks/vendors/basicSetting";

import FullScreenLoaderAtom from "atoms/FullScreenLoader";

import StorageGateway from "lib/storage-gateway";

class MatchWhenAuthorized extends React.Component {
  constructor(props) {
    super(props);
    this.checkProfile = this.checkProfile.bind(this);
    this.checkMobileAndEmailVerification =
      this.checkMobileAndEmailVerification.bind(this);
    this.pathNotPermitted = this.pathNotPermitted.bind(this);
    this.getCustomerDetails = this.getCustomerDetails.bind(this);
    this.checkAccountValidity = this.checkAccountValidity.bind(this);
    this.state = { isLoading: false };
  }

  componentWillMount() {
    const { profile } = this.props;
    if (!Object.keys(profile).length) {
      this.setState({ isLoading: true });

      this.props.BasicSettingDucks.getBasicSetting()
        .then(() => {})
        .catch(() => {});
      this.props.UserDucks.getProfileUser()
        .then(() => {
          this.getCustomerDetails();

          Promise.all([
            this.props.UserDucks.getAccessUser(),
            this.props.UserDucks.getExtraData(),
          ]).finally(() => {
            this.setState({ isLoading: false });
          });
        })
        .catch(() => this.setState({ isLoading: false }));
    }
    // else{
    //   this.props.UserDucks.getProfileUser();
    //   this.getCustomerDetails();
    // }
  }

  getCustomerDetails() {
    const { profile, isBusinessAdmin } = this.props;
    if (!isBusinessAdmin) {
      if (profile.company.parent_company) {
        this.props.PaymentDucks.getCustomer(profile.company.parent_company).then(()=>{}).catch(()=>{});
      } else {
        this.props.PaymentDucks.getCustomer(profile.company.id).then(()=>{}).catch(()=>{});
      }
    }
  }

  checkAccountValidity(props) {
    const {
      component: Component,
      name,
      isBusinessAdmin,
      customerDetail,
      isSubCompany,
      profile,
    } = this.props;
    const accountSuspended =
      customerDetail && customerDetail.commercial_status === "Suspended";

    const cancellationInProgress =
      customerDetail &&
      customerDetail.commercial_status === "Cancellation in progress";

    const noBilling =
      customerDetail && customerDetail.commercial_status === "No Billing";

    const termsAccepted = profile && profile.tc_data;

    if (!isBusinessAdmin) {
      if (termsAccepted) {
        if (name === "accounts.terms&condn")
          return <Navigate to="/dashboard" />;
        return <Component {...props} />;
      }
      if (isSubCompany || noBilling) {
        if (
          name === "vendor.plan" ||
          name === "vendor.invoice" ||
          name === "vendor.payment-method" ||
          name === "vendor.invoice.detail"
        )
          return <Navigate to="/dashboard" />;
        return <Component {...props} />;
      }
      if (accountSuspended) {
        if (name !== "accounts.suspension") {
          return <Navigate to="/accounts/suspension" />;
        }
        return <Component {...props} />;
      } else if (cancellationInProgress) {
        if (name !== "accounts.cancellation") {
          return <Navigate to="/accounts/cancellation" />;
        }
        return <Component {...props} />;
      } else if (!accountSuspended) {
        if (name === "accounts.suspension") return <Navigate to="/dashboard" />;
        return <Component {...props} />;
      } else if (!cancellationInProgress) {
        if (name === "accounts.cancellation")
          return <Navigate to="/dashboard" />;
        return <Component {...props} />;
      } else if (this.pathNotPermitted(name)) {
        if (name !== "dashboard") return <Navigate to="/dashboard" />;
      } else return <Component {...props} />;
    }
  }

  checkMobileAndEmailVerification(props) {
    const { component: Component, name, isBusinessAdmin, profile } = this.props;
    const emailVerfied = profile.company && profile.company.is_email_verified;
    const phoneVerfied = profile.company && profile.company.is_phone_verified;
    const passwordExpiry = profile && profile.password_expiry > 90;
    const termsAccepted =
      profile && profile.tc_data && profile.tc_data.tc_agreed;
    const oAuthToken = StorageGateway.get("oAuthToken");

    if (!isBusinessAdmin) {
      if (!emailVerfied && !phoneVerfied) {
        if (name !== "accounts.activate_user")
          return <Navigate to="/accounts/activate-user" />;
        return <Component {...props} />;
      }
      if (!termsAccepted && oAuthToken) {
        if (name !== "accounts.terms&condn")
          return <Navigate to="/accounts/terms&condition" />;
        return <Component {...props} />;
      } else if (passwordExpiry) {
        if (name !== "accounts.change-expired-password") {
          return <Navigate to="/accounts/change-expired-password" />;
        }
      } else if (this.pathNotPermitted(name)) {
        if (name !== "dashboard") return <Navigate to="/dashboard" />;
      }
      return this.checkAccountValidity(props);
    } else if (isBusinessAdmin) {
      if (name == "accounts.terms&condn")
        return <Navigate to="/vendor/list/" />;
      if (this.pathNotPermitted(name)) {
        return <Navigate to="/vendor/list/" />;
      }
      return <Component {...props} />;
    }
  }

  checkProfile(props) {
    const { profile } = this.props;
    const oAuthToken = StorageGateway.get("oAuthToken");
    if (Object.keys(profile).length) {
      return this.checkMobileAndEmailVerification(props);
    } else if (!Object.keys(profile).length) {
      return <Navigate to="/accounts/login" />;
      cookie.remove("lastActivityTime", { path: "/" });
    }
    return <Navigate to="/accounts/login" />;
  }

  pathNotPermitted(routeName) {
    const { accessPerms } = this.props;
    return accessPerms.filter((item) => item === routeName).length === 0;
  }

  render() {
    const { component: Component, ...rest } = this.props;
    const { isLoading } = this.state;
    if (isLoading) return <FullScreenLoaderAtom active />;
    return this.checkProfile(this.props);
  }
}

const mapStateToProps = (state) => ({
  accessPerms: UserDucks.accessPerms(state),
  isBusinessAdmin: UserDucks.isBusinessAdmin(state),
  isPayrollCreated: UserDucks.isPayrollCreated(state),
  profile: UserDucks.profile(state),
  userCompany: UserDucks.userCompany(state),
  customerDetail: PaymentDucks.customerDetail(state),
  isSubCompany: UserDucks.isSubCompany(state),
});

const mapActionsToProps = (dispatch) => ({
  BasicSettingDucks: bindActionCreators(BasicSettingDucks, dispatch),
  UserDucks: bindActionCreators(UserDucks, dispatch),
  PaymentDucks: bindActionCreators(PaymentDucks, dispatch),
});

MatchWhenAuthorized.propTypes = {
  accessPerms: PropTypes.array.isRequired,
  component: PropTypes.func.isRequired,
  isBusinessAdmin: PropTypes.bool,
  isPayrollCreated: PropTypes.bool,
  profile: PropTypes.object.isRequired,
  BasicSettingDucks: PropTypes.object.isRequired,
  UserDucks: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapActionsToProps)(MatchWhenAuthorized);
