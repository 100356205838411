import React from "react";
import PropTypes from "prop-types";

import TwoColumnLayout from "layouts/TwoColumn";
import StorageGateway from "lib/storage-gateway";

import { NAV } from "constants/notification";
import LayerAtom from "../../atoms/Layer";
import { toast } from "react-toastify";
import withRouter from "helpers/withRouter";

/**
 * AdjustmentTypesListPage
 *
 * Layout:
 *    - {@link SSTCFLayout}
 *
 * Sections:
 *    - {@link AdjustmentTypesFilterSection}
        {@link AdjustmentTypesListSection}
 *
 */

class NotificationListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authToken: null,
      userId: null,
      nre_company_id: null,
      company_id: null,
      broadcastFlag: false,
    };
    this.loadData = this.loadData.bind(this);
    this.eventListnerFunction = this.eventListnerFunction.bind(this);
    this.postMessageToChild = this.postMessageToChild.bind(this);
  }
  postMessageToChild(messageType) {
    const iFrame = document.getElementById("worksanaNre");
    iFrame.contentWindow.postMessage(messageType, "*");
  }
  loadData() {
    this.postMessageToChild("REFRESH_NOTIFICATION_TABLE");
  }
  eventListnerFunction(event) {
    const data =
      event.data && typeof event.data !== "object" && JSON.parse(event.data);
    const message = data?.message;

    if (message === "BROADCAST") {
      this.setState({ broadcastFlag: true });
    } else if (message === "FAILED_TOAST") {
      toast.error(data?.content?.toastMessage);
    } else if (message === "SUCCESS_TOAST") {
      toast.success(data?.content?.toastMessage);
    } else if (message === "BROADCAST_SUCCESS") {
      this.loadData();
      toast.success(data?.content?.toastMessage);
    } else if (message === "REMINDER_SENDS_SUCCESS") {
      this.loadData();
      toast.success(data?.content?.toastMessage);
    }
  }

  componentDidMount() {
    window.addEventListener("message", this.eventListnerFunction, false);
    const oAuthToken = StorageGateway.get("graniteToken");
    const userId = StorageGateway.get("userId");
    const nre_company_id = StorageGateway.get("nre_company_id");
    const company_id = StorageGateway.get("company_id");
    this.setState({
      authToken: oAuthToken,
      userId: userId,
      nre_company_id: nre_company_id,
      company_id: company_id,
    });
  }
  componentWillUnmount() {
    window.removeEventListener("message", this.eventListnerFunction);
  }

  render() {
    const {
      authToken,
      userId,
      nre_company_id,
      company_id,
      broadcastFlag,
    } = this.state;
    const { history } = this.props;

    return (
      <TwoColumnLayout
        navInfo={NAV.NotificationListPage}
        fetchData={this.loadData}
      >
        <div
          style={{
            height: "calc(100vh - 102px - 75px)",
            marginRight: "-15px",
          }}
        >
          <iframe
            id="worksanaNre"
            title="worksana-nre"
            width="100%"
            height="100%"
            frameBorder="0"
            style={{ width: "100%", height: "100%" }}
            src={`${
              process.env.REACT_APP_WMAPPER_BASE_URL
            }/notification/list?access_token=${authToken}&user_id=${userId}&companyId=${company_id ||
              nre_company_id}&content_type=${
              this.props?.location?.pathname.indexOf("learning") > -1
                ? "LEARNING_CENTER"
                : "COMMUNICATION_CENTER"
            }`}
          />
        </div>
        {broadcastFlag && (
          <LayerAtom
            active={true}
            path={`/notification/broadcast/?access_token=${authToken}&user_id=${userId}&companyId=${company_id ||
              nre_company_id}&content_type=${
                this.props?.location?.pathname.indexOf("learning") > -1
                  ? "LEARNING_CENTER"
                  : "COMMUNICATION_CENTER"
              }`}
            setToggle={() => this.setState({ broadcastFlag: false })}
          />
        )}
      </TwoColumnLayout>
    );
  }
}

NotificationListPage.propTypes = {
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
  AdjustmentTypesDucks: PropTypes.object.isRequired,
};

export default withRouter(NotificationListPage);
