import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import cookie from "react-cookies";

import * as UserDucks from "ducks/accounts/user";
import * as BasicSettingDucks from "ducks/vendors/basicSetting";
import FullScreenLoaderAtom from "atoms/FullScreenLoader";

class MatchWhenUnauthorized extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false };
  }

  componentWillMount() {
    const { profile } = this.props;
    if (!Object.keys(profile).length) {
      cookie.remove("lastActivityTime", { path: "/" });
      this.setState({ isLoading: true });
      Promise.all([
        this.props.UserDucks.getProfileUser(),
        this.props.UserDucks.getAccessUser(),
        this.props.UserDucks.getExtraData(),
        this.props.BasicSettingDucks.getBasicSetting(),
      ])
        .then(() => this.setState({ isLoading: false }))
        .catch(() => this.setState({ isLoading: false }));
    }
  }

  render() {
    const { component: Component, profile } = this.props;
    const { isLoading } = this.state;
    const emailVerified = profile.company && profile.company.is_email_verified;
    const phoneVerified = profile.company && profile.company.is_phone_verified;

    if (isLoading) return <FullScreenLoaderAtom active />;

    if (Object.keys(profile).length && (emailVerified || phoneVerified)) {
      return <Navigate to="/dashboard" />;
    } else if (
      Object.keys(profile).length &&
      !emailVerified &&
      !phoneVerified
    ) {
      return <Navigate to="/accounts/activate-user" />;
    }

    // Return the passed component (element) if not redirected
    return <Component />;
  }
}

const mapStateToProps = (state) => ({
  profile: UserDucks.profile(state),
});

const mapActionsToProps = (dispatch) => ({
  BasicSettingDucks: bindActionCreators(BasicSettingDucks, dispatch),
  UserDucks: bindActionCreators(UserDucks, dispatch),
});

MatchWhenUnauthorized.propTypes = {
  element: PropTypes.element.isRequired, // Ensure element is passed as JSX
  profile: PropTypes.object.isRequired,
  BasicSettingDucks: PropTypes.object.isRequired,
  UserDucks: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(MatchWhenUnauthorized);
