import React from "react";

import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import routesMapping from "constants/routes";
import styles from "./styles.module.scss";
import ShiftIcon from "./ShiftIcon.png";
import { server } from "helpers/config";
import withRouter from "helpers/withRouter";

class CollapseNav extends React.PureComponent {
  /* eslint-disable */
  render() {
    const {
      nav,
      images,
      isSelected,
      isChildSelected,
      userExtraData,
      navigate
    } = this.props;
    return (
      <div>
        {nav.map((i) => {
          if (i.children.length) {
            return (
              <div
                tabindex="0"
                role="button"
                className={styles["tooltip-custom"]}
                className={
                  isChildSelected(i)
                    ? `${styles["item-selected"]} ${styles["tooltip-custom"]}`
                    : styles["tooltip-custom"]
                }
              >
                <div>{images[i.name]}</div>
                <div className={styles["tooltiptext"]}>
                  {i.children.map((item) => (
                    <NavLink
                      to={routesMapping[item.name]}
                      className={styles.navCollapseItem}
                      activeClassName={
                        isSelected(item.name)
                          ? styles["item-selected"]
                          : styles["navCollapseItemhover"]
                      }
                    >
                      {item.label}
                    </NavLink>
                  ))}
                </div>
              </div>
            );
          }
          return (
            <NavLink
              to={routesMapping[i.name]}
              activeClassName={
                isSelected(i.name) ? styles["item-selected"] : ""
              }
            >
              {images[i.name]}
            </NavLink>
          );
        })}
        {(userExtraData?.company_permissions?.work_shift ||
          userExtraData?.company_permissions?.paid_time_off) &&
          !userExtraData?.is_business_admin && (
            <div className={styles["tooltiptext"]}>
              {/*userExtraData.company_permissions.work_shift && <a href={server.schedulingHost} style={{marginTop: '4px'}}>
            <img src={ShiftIcon} alt="icon" className={styles.nodeIconSvgCollapsed} />
            </a>
        */}

              <div
                tabindex="0"
                role="button"
                className={styles["tooltip-custom"]}
                className={
                  isSelected("pto.list")
                    ? `${styles["item-selected"]} ${styles["tooltip-custom"]}`
                    : styles["tooltip-custom"]
                }
              >
                <div>{images["premium_features.list"]}</div>
                <div className={styles["tooltiptext"]}>
                  <a
                    href={server.schedulingHost}
                    style={{ marginTop: "4px" }}
                    className={styles.navCollapseItem}
                  >
                    Scheduling
                  </a>
                  {(userExtraData.company_permissions.paid_time_off && (userExtraData.company_permissions.company_owner || userExtraData.pto_supervisor))&& (
                    <NavLink
                      to={routesMapping["pto.list"]}
                      className={styles.navCollapseItem}
                      activeClassName={
                        isSelected("pto.list")
                          ? styles["item-selected"]
                          : styles["navCollapseItemhover"]
                      }
                    >
                      Time Off Request
                    </NavLink>
                  )}
                </div>
              </div>
            </div>
          )}
      </div>
    );
  }
}

CollapseNav.defaultProps = {
  nav: [
    {
      id: 1,
      name: "dashboard",
      label: "Overview",
      children: [],
    },
  ],
};

CollapseNav.propTypes = {
  nav: PropTypes.array.isRequired,
};

export default withRouter(CollapseNav);
