import {
  useNavigate,
  useLocation,
  useParams,
  useNavigationType,
  useSearchParams,
} from "react-router-dom";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const navigationType = useNavigationType();
    const params = useParams();
    const queryParams = Object.fromEntries(
      new URLSearchParams(searchParams.toString())
    );
    const updateQuery = (key, value) => {
      // Modify the searchParams object
      searchParams.set(key, value); // Add or update the key-value pair
      setSearchParams(searchParams); // Update the URL
    };
    
    const updateMultipleQueries = (queries) => {
      const updatedSearchParams = new URLSearchParams(searchParams); // Clone current searchParams
      Object.entries(queries).forEach(([key, value]) => {
        updatedSearchParams.set(key, value); // Set or update the key-value pair
      });
      setSearchParams(updatedSearchParams); // Update the URL
    };

    return (
      <Component
        {...props}
        location={location}
        navigate={navigate}
        params={params}
        query={queryParams}
        navigationType={navigationType}
        updateQuery={updateQuery}
        updateMultipleQueries={updateMultipleQueries}
        setSearchParams={setSearchParams}
      />
    );
  }

  return ComponentWithRouterProp;
}

export default withRouter;
