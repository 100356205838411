import React from 'react';

import PropTypes from 'prop-types';
import { Media } from 'reactstrap';

import { DropdownToggle } from 'reactstrap';
import { DropdownMenu } from 'reactstrap';
import { DropdownItem } from 'reactstrap';
import { UncontrolledDropdown } from 'reactstrap';

import styles from './styles.module.scss';
import Logo from './company-logo-placeholder.png';

const NavDropdownComponent = (props) => {
  const { dropdownItems, tether, title, userCompany, goTo, isFinished, isSuspended, isCancellationInProgress,
    sidemenuCollapsed } = props;
  const handleClick = item => (item.action ? item.action() : goTo(item.href));
  return (
    <div className={!sidemenuCollapsed ? styles['sidenav-dropdown'] : styles['sidenav-dropdown-collapse']}>
      <UncontrolledDropdown tether={tether} id="sidenav-dropdown" direction='end'>
        <DropdownToggle nav caret >
          {userCompany.image
            ? (<Media>
              <div className="d-inline">
                <div className={!sidemenuCollapsed ? styles['image-upload'] : styles['image-upload-collapse']}>
                  <img
                    className={!sidemenuCollapsed
                      ? styles['sidenav-logo-upload']
                      : styles['sidenav-logo-upload-collapse']
                    }
                    src={userCompany.image && userCompany.image.small.url}
                    alt="Logo"
                  />
                </div>
              </div>
              { !sidemenuCollapsed
                ? <Media body className="d-inline-block" style={{ alignSelf:'center'}}>
                    <Media title={title} className={styles['sidenav-dropdown__title']}>
                      {title}
                    </Media>
                    <Media title={userCompany.name} className={styles['company-name']}>
                      {userCompany.name}
                    </Media>  
                </Media>
                : null
              }
            </Media>)
            : (<Media>
              <div className="d-inline">
                <div>
                  <img
                    alt="Company logo"
                    className={!sidemenuCollapsed
                      ? styles['sidenav-logo']
                      : styles['sidenav-logo-upload-collapse']
                    }
                    height="50"
                    width="50"
                    src={Logo}
                    title="Upload Company Logo"
                  />
                </div>
              </div>
              { !sidemenuCollapsed
                ? <Media body style={{ alignSelf:'center'}}>
                  <Media title={title} className={styles['sidenav-dropdown__title']}>
                    {title}
                  </Media>
                  <Media title={userCompany.name} className={styles['company-name']}>
                    {userCompany.name}
                  </Media> 
                </Media>
                : null 
              }
            </Media>)
          }
        </DropdownToggle>
        <DropdownMenu style={{ inset: 'auto'}}>
          {dropdownItems.map(item => (
            item.children
              ? (<span key={`${item.label}${Math.random()}`}>
                <DropdownItem header>{item.label}</DropdownItem>
                <DropdownItem divider />
                {item.children.map(child => (
                  <DropdownItem
                    key={child.href}
                    disabled={isFinished || isSuspended || isCancellationInProgress}
                    onClick={() => handleClick(child)}
                  >
                    {child.label}
                  </DropdownItem>
                ))}
              </span>)
              : (<DropdownItem
                key={item.label}
                onClick={() => handleClick(item)}
              >
                {item.label}
              </DropdownItem>)
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
}

NavDropdownComponent.defaultProps = {
  tether: {
    targetAttachment: 'bottom right',
    offset: '300px 10px',
  },
};

NavDropdownComponent.propTypes = {
  dropdownItems: PropTypes.array.isRequired,
  goTo: PropTypes.func,
  tether: PropTypes.object,
  title: PropTypes.string,
  userCompany: PropTypes.object,
};

NavDropdownComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default NavDropdownComponent;
