import { createSelector } from 'reselect';
import axios from 'helpers/interceptor';
import createReducer from 'helpers/createReducer';
import URLS from './urls';

const GET_INFORMATIONS = 'info/info/GET_INFORMATIONS';
const GET_INFORMATIONS_FULFILLED = 'info/info/GET_INFORMATIONS_FULFILLED';
const RESET_INFORMATIONS = 'activities/missingActivity/RESET_INFORMATIONS';

export function getInformations(params) {
  return {
    type: GET_INFORMATIONS,
    payload: axios.get(URLS.INFO, { params }),
  };
}

export function resetInformations() {
  return {
    type: RESET_INFORMATIONS,
  };
}

const defaultState = {
  list: { results: [] },
  detail: {},
};

function GET_INFORMATIONS_FULFILLED_REDUCER(state, action) {
  return {
    ...state,
    list: action.payload,
  };
}

function RESET_INFORMATIONS_REDUCER(state) {
  return {
    ...state,
    ...defaultState,
  };
}

const handlers = {
  [GET_INFORMATIONS_FULFILLED]: GET_INFORMATIONS_FULFILLED_REDUCER,
  [RESET_INFORMATIONS]: RESET_INFORMATIONS_REDUCER,
};

const infoSelector = state => state.info?.info || {}; // Handle undefined info safely

export const infoList = createSelector(
  infoSelector,
  instance => instance.list || { results: [] }, // Default if list is undefined
);

export const infoDetail = createSelector(
  infoSelector,
  instance => instance.detail || {}, // Default if detail is undefined
);

export default createReducer(defaultState, handlers);
