import React from 'react';
import PropTypes from 'prop-types';
import Slider from "react-slick";

import { Row } from 'reactstrap';
import { Col } from 'reactstrap';

import VideoModalComponent from 'components/common/CollapsableInfo/VideoModal';
import Lightbox from 'react-image-lightbox';

import PlayIcon from './play-btn.png';
import {ReactComponent as PdfIcon} from './pdf.svg';

import styles from './styles.module.scss';

class collapsableInfoComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { isVideoModalOpen: false, videoOrImage: null, isOverlayOpen: false, imageSrc: {} };
    this.toggleVideoModal = this.toggleVideoModal.bind(this);
  }

  toggleVideoModal(selectedItem){
    this.setState(({ isVideoModalOpen, videoOrImage }) => ({ isVideoModalOpen: !isVideoModalOpen, videoOrImage: selectedItem }));
  }

  render(){
    const { infoList, isSignUp } = this.props;
    const { videoOrImage, isVideoModalOpen, isOverlayOpen, imageSrc } = this.state;
    const settings = {
      dots: false,
      //  infinite: true,
      infinite: false,
      speed: .1,
      slidesToShow: 1,
      slidesToScroll: 1,
      //  slidesPerShow: 5,
      //  centerMode: true,
      variableWidth: false,
    };

    return(
      <div>
        <Slider {...settings} >
          {
            infoList.map((item, index)=> {
              if(item.kb_data_type === 'Video'){
                return(
                  <div key={item.id}>
                    <Row>
                      <Col md={isSignUp ? 4 : 3} className="d-flex justify-content-center pr-0">
                        <div className={styles['video-wrapper']}>
                          <video
                            className={styles['video-content']}
                            muted
                            src={item.external_reference}
                          >
                            <track kind="captions" />
                          </video>
                          <div tabIndex="0" role="button" onClick={()=> this.toggleVideoModal(item)}>
                            <img src={PlayIcon} alt='' className={styles['overlay-play-button']} />
                          </div>
                        </div>
                      </Col>
                      <Col md={isSignUp ? 8 : 9} className="d-flex align-items-center pl-0">
                        <div className={styles['rich-text']}>
                          {(item.rich_text_content.length < 190) ? item.rich_text_content : item.rich_text_content.substring(0, 188).concat("...")}
                        </div>
                      </Col>
                    </Row>
                  </div>
                ); 
              } else if (item.kb_data_type === 'Image'){
                return (
                  <div key={item.id}>
                    <Row>
                      <Col md={isSignUp ? 4 : 3} className="d-flex justify-content-center pr-0">
                        <div className={`${styles['img-wrap']} outline-none`} tabIndex="0" role="button" onClick={()=> this.setState({ selectedId: item.id, isOverlayOpen: true, imageSrc: item.external_reference})}>
                          <img src={item.external_reference} alt=''  />
                        </div>
                      </Col>
                      <Col md={isSignUp ? 8 : 9} className="d-flex align-items-center pl-0">
                        <div className={styles['rich-text']}>{(item.rich_text_content.length < 190) ? item.rich_text_content : item.rich_text_content.substring(0, 188).concat("...")}</div>
                      </Col>
                    </Row>
                  </div>
                );
              }
              else if (item.kb_data_type === 'Document'){
                return (
                  <div key={item.id}>
                    <Row>
                      <Col md={isSignUp ? 4 : 3} className="d-flex justify-content-center pr-0">
                        <div className={`${styles['document-wrap']} d-flex justify-content-center align-items-center`}>
                          <a rel="noopener noreferrer" download={item.external_reference} target="_blank" href={item.external_reference}>
                            <PdfIcon height="100" width="100"/>
                          </a>
                        </div>
                      </Col>
                      <Col md={isSignUp ? 8 : 9} className="d-flex align-items-center pl-0">
                        <div className={styles['rich-text']}>{(item.rich_text_content.length < 190) ? item.rich_text_content : item.rich_text_content.substring(0, 188).concat("...")}</div>
                      </Col>
                    </Row> 
                  </div>
                );
              }
              return true;
            })
          }
        </Slider>
        <VideoModalComponent
          toggle={this.toggleVideoModal}
          isVideoModalOpen={isVideoModalOpen}
          videoOrImage={videoOrImage}
        />
        {isOverlayOpen && (
          <Lightbox
            mainSrc={imageSrc}
            onCloseRequest={() => this.setState({ isOverlayOpen: false })}
          />
        )}
      </div>
    );
  }
}

collapsableInfoComponent.propTypes = {
  toggle: PropTypes.func,
};

export default collapsableInfoComponent;