import { combineReducers } from 'redux';

import job from './job';
import jobEmployeeMapping from './jobEmployeeMapping';
import task from './task';
import jobTaskMapping from './jobTaskMapping';
import comment from './comment';
import tag from './tag';
import taskTag from './taskTag';
import reports from './reports';

export default combineReducers({
  job,
  jobEmployeeMapping,
  task,
  jobTaskMapping,
  comment,
  tag,
  reports,
  taskTag,
});
