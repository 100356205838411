import React from 'react';

import PropTypes from 'prop-types';

import { Modal } from 'reactstrap';
import { ModalBody } from 'reactstrap';
import { ModalHeader } from 'reactstrap';

import styles from './styles.module.scss';

const  VideoModalComponent = (props) => {
  const { isVideoModalOpen, toggle, videoOrImage } = props;
  return (
    <Modal
      isOpen={isVideoModalOpen}
      toggle={toggle}
      className={styles['video-modal-width']}
    >
      <ModalHeader toggle={toggle} className={styles['help-model-header']}/>
      <ModalBody className="">
        <div className="d-flex justify-content-center">
          {(videoOrImage && videoOrImage.kb_data_type === 'Video') ?
            <div className={styles['modal-video-wrapper']}>
              <video
                width="650"
                className={styles['video-content']}
                controls
                muted
                autoPlay
                src={videoOrImage.external_reference}
              >
                <track kind="captions" />
              </video>
            </div> 
            : null}
          {(videoOrImage && videoOrImage.kb_data_type === 'Image') 
            ?<div className={`${styles['modal-img-wrap']} d-flex justify-content-center`} >
              <img src={videoOrImage.external_reference} alt='' />
            </div>
            : null
          }
        </div>
      </ModalBody>
    </Modal>
  );
};



VideoModalComponent.propTypes = {
  isVideoModalOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  videoOrImage: PropTypes.object,
};

export default VideoModalComponent;