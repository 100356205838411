import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux'; 

import * as UserDucks from 'ducks/accounts/user';

import LogoMainBlueAtom from 'atoms/LogoMainBlue';
import HomeGetStartedComponent from 'components/generics/home/GetStarted';
import styles from './styles.module.scss';
import name from './brand_name.png';

/**
 * HomePage -> HomeHeroSection
 *
 * Components:
 *    - {@link HomeGetStartedComponent}
 *
 * State:
 *    None
 *
 * Actions:
 *    - GetStarted:
 *        - Link to Dashboard or Login
 */
const HomeHeroSection = props => (
  <section className={styles.wrapper}>
    <div className="pb-3 pt-1">
      <LogoMainBlueAtom height="85" className={styles.logo} width="130"/><br />
      <img src={name} height="26px" width="150px" alt="Logo" className="mb-3" />
    </div>
    <h3 className={styles.welcome_text}>Welcome to Worksana</h3>
    <p className={styles.description}>Go track your employees shifts and timecards!</p>
    <HomeGetStartedComponent profile={props?.profile} />
  </section>
);

const mapStateToProps = state => ({
  profile: UserDucks.profile(state),
});

HomeHeroSection.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
)(HomeHeroSection);
