import React, { useEffect,memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import cookie from "react-cookies";

import * as UserDucks from "ducks/accounts/user";
import * as BasicSettingDucks from "ducks/vendors/basicSetting";
import FullScreenLoaderAtom from "atoms/FullScreenLoader";

const MatchAlways = ({
  profile,
  component: Component,
  UserDucks,
  BasicSettingDucks,
}) => {
  const [loader,setLoader] = useState(!Object.keys(profile).length)
  useEffect(() => {
    if (!Object.keys(profile).length) {
      cookie.remove("lastActivityTime", { path: "/" });
      Promise.all([
        UserDucks.getProfileUser(),
        UserDucks.getAccessUser(),
        UserDucks.getExtraData(),
        BasicSettingDucks.getBasicSetting(),
      ]).catch((e) => console.error(e)).finally(()=>setLoader(false));
    }
  }, [profile, UserDucks, BasicSettingDucks]);

  if(loader) return <FullScreenLoaderAtom active/>
  return <Component />;
};

const mapStateToProps = (state) => ({
  profile: UserDucks.profile(state),
});

const mapActionsToProps = (dispatch) => ({
  BasicSettingDucks: bindActionCreators(BasicSettingDucks, dispatch),
  UserDucks: bindActionCreators(UserDucks, dispatch),
});

MatchAlways.propTypes = {
  component: PropTypes.elementType.isRequired,
  profile: PropTypes.object.isRequired,
  BasicSettingDucks: PropTypes.object.isRequired,
  UserDucks: PropTypes.object.isRequired,
};

export default  memo(connect(mapStateToProps, mapActionsToProps)(MatchAlways));
